<template>
  <div class="flex items-start flex-col w-full">
    <div class="flex flex-wrap w-full items-center gap-2.5">
      <div>
        <el-tooltip content="Shift + V" :show-after="300" placement="top">
          <el-checkbox border v-model="form.skip">Пропустить</el-checkbox>
        </el-tooltip>
      </div>
      <el-tooltip
        content="Сохранить (Ctrl + S)"
        placement="top"
        :show-after="300"
      >
        <el-button type="primary" plain @click="saveChat"
          >Сохранить</el-button
        ></el-tooltip
      >
    </div>
    <div v-if="!rule_for_symbols" class="w-full mt-2">
      <el-input
        ref="transcribe-input"
        id="transcribe-input"
        class="mt-2 h-12"
        type="text"
        :disabled="form.skip"
        v-model="form.text"
        @input="validateText"
        @keydown="handleKeydown"
      />
      <div
        v-if="invalidWords && invalidWords.length > 0"
        class="text-xs text-red-500 mt-1 break-words"
      >
        {{ invalidWords }}<span class="ml-0.5">не найдены в словаре.</span>
      </div>
      <span v-if="errorMessage" class="text-xs text-red-500">{{
        errorMessage
      }}</span>
    </div>
    <div v-else class="w-full mt-2">
      <el-input
        ref="transcribe-input"
        id="transcribe-input"
        class="mt-2"
        type="text"
        :disabled="form.skip"
        v-model="form.text"
        @keydown="handleKeydown"
      />
    </div>
    <!--    <div class="w-full mt-2 flex items-center justify-end flex-wrap gap-y-2">-->
    <!--      <el-button-->
    <!--        type="primary"-->
    <!--        plain-->
    <!--        v-if="form.lineIndex > -1"-->
    <!--        :disabled="isButtonDisabled && !rule_for_symbols"-->
    <!--        @click="save"-->
    <!--      >-->
    <!--        {{ "Обновить" }}-->
    <!--      </el-button>-->
    <!--      <el-button-->
    <!--        type="primary"-->
    <!--        plain-->
    <!--        v-else-->
    <!--        :disabled="isButtonDisabled && !rule_for_symbols"-->
    <!--        @click="save"-->
    <!--      >-->
    <!--        {{ "Добавить" }}-->
    <!--      </el-button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import apiClient from "@/api/api-client";
import store from "@/store";

export default {
  name: "FillingFormFullAudio",
  props: {
    lang: {
      type: String,
      default: "kz",
    },
    lines: {
      type: Array,
    },
    lineIndex: {
      type: Number,
    },
    rule_for_symbols: Number,
  },
  data() {
    return {
      form: {
        text: "",
        skip: false,
      },
      errorMessage: "",
      invalidWords: [],
      debounce: null,
      isButtonDisabled: true,
      editingLineIndex: null,
    };
  },
  watch: {
    lines: {
      immediate: true,
      handler(newLines) {
        if (newLines && newLines.length > 0) {
          this.form.text = newLines[0].text || "";
        }
      },
    },
  },
  mounted() {
    this.$refs["transcribe-input"].focus();
    if (this.lines && this.lines.length > 0) {
      this.form.text = this.lines[1].text || ""; // Safely set form.text to the first text
    }
    window.addEventListener("keydown", this.handleSave);
    window.addEventListener("keydown", this.handleSkip);
    this.saveInterval = setInterval(() => {
      store.dispatch("notify/add", {
        type: "info",
        message: "Автосохранение",
        size: "mini",
      });
      this.saveChat();
    }, 30000);
  },
  beforeUnmount() {
    if (this.saveInterval) {
      clearInterval(this.saveInterval);
    }
    window.removeEventListener("keydown", this.handleSave);
    window.removeEventListener("keydown", this.handleSkip);
  },
  methods: {
    validateText() {
      const text = this.form.text;
      const errors = [];

      // Validate text for parentheses, allowed characters, and empty symbols
      if (!this.checkBalancedParentheses(text)) {
        errors.push("Небалансированные или вложенные скобки.");
      }
      if (!this.checkAllowedCharacters(text)) {
        errors.push("Некорректные символы вне или внутри скобок.");
      }
      if (this.checkEmptySymbols(text)) {
        errors.push("Пустые скобки или хеши недопустимы.");
      }

      // Set error messages and button status based on validation
      if (errors.length > 0) {
        this.errorMessage = errors.join(" ");
        this.isButtonDisabled = true;
      } else {
        this.errorMessage = "";
        this.isButtonDisabled = false;
      }

      // Clear previous debounce if it exists
      if (this.debounce) {
        clearTimeout(this.debounce);
      }

      // Check if the last character is a word boundary (space or punctuation)
      const lastChar = text.slice(-1);
      if (/\s|\p{P}/u.test(lastChar)) {
        // Only trigger API call if last character is space or punctuation
        this.debounce = setTimeout(async () => {
          const words = text.split(/\s+/).filter((word) => word.length > 0); // Split input into words

          const validationSymbols =
            /^[АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҮҰФХҺЦЧШЩЪЫІЬЭЮЯаәбвгғдеёжзийкқлмнңоөпрстуүұфхһцчшщъыіьэюя]*$/u;

          // Clear previous invalid words before checking again
          this.invalidWords = [];

          // Process each word independently and validate with API
          for (const word of words) {
            if (validationSymbols.test(word)) {
              try {
                const response = await apiClient.get(`/word`, {
                  params: { text: word },
                });
                // Add to invalid words list if word is not valid
                if (!response.data._success) {
                  this.invalidWords.push(word);
                }
              } catch (error) {
                console.error(error);
                this.invalidWords.push(word);
              }
            }
          }
        }, 300);
      } else {
        // Clear invalid words if there's no space or punctuation (user is still typing a word)
        this.invalidWords = [];
      }
    },
    checkBalancedParentheses(text) {
      let stack = [];
      let balanced = true;
      let hasNesting = false;

      for (let i = 0; i < text.length; i++) {
        let char = text[i];

        // parentheses
        if (char === "(") {
          stack.push("(");
          if (stack.filter((c) => c === "(").length > 1) hasNesting = true;
        } else if (char === ")") {
          if (stack.length === 0 || stack.pop() !== "(") {
            balanced = false;
            break;
          }
        }

        // square brackets
        if (char === "[") {
          stack.push("[");
          if (stack.filter((c) => c === "[").length > 1) hasNesting = true;
        } else if (char === "]") {
          if (stack.length === 0 || stack.pop() !== "[") {
            balanced = false;
            break;
          }
        }
        if (char === "#") {
          if (stack.length === 0 || stack[stack.length - 1] !== "#") {
            // Opening "#", push to stack
            stack.push("#");
          } else {
            // Closing "#", check for balance
            stack.pop();
          }

          // Check if there are more than one "#" in the stack
          if (stack.filter((c) => c === "#").length > 1) {
            hasNesting = true;
          }
        }
      }

      if (stack.length !== 0) balanced = false;

      return balanced && !hasNesting;
    },

    checkAllowedCharacters(text) {
      // Extract the parts outside of (), [], and ## pairs
      const outsideParenthesesBracketsHashes = text.replace(
        /\(.*?\)|\[.*?\]|#.*?#/g,
        ""
      );

      // Extract the parts inside (), [], and ## pairs
      const insideParentheses = text.match(/\(.*?\)/g) || [];
      const insideBrackets = text.match(/\[.*?\]/g) || [];
      const insideHashes = text.match(/#(.*?)#/g) || [];

      // outside of (), [], and ##)
      const outsideRegex =
        /^[ АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҮҰФХҺЦЧШЩЪЫІЬЭЮЯаәбвгғдеёжзийкқлмнңоөпрстуүұфхһцчшщъыіьэюя.,!?'\-#[\]()]*$/u;

      // ()
      const insideParenthesesRegex =
        /^[ АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҮҰФХҺЦЧШЩЪЫІЬЭЮЯаәбвгғдеёжзийкқлмнңоөпрстуүұфхһцчшщъыіьэюяabcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.\-'&@]*$/;

      // []
      const insideBracketsRegex =
        /^[ АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҮҰФХҺЦЧШЩЪЫІЬЭЮЯаәбвгғдеёжзийкқлмнңоөпрстуүұфхһцчшщъыіьэюяabcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.\-'&@]*$/;

      // ##
      const insideHashesRegex =
        /^[ АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҮҰФХҺЦЧШЩЪЫІЬЭЮЯаәбвгғдеёжзийкқлмнңоөпрстуүұфхһцчшщъыіьэюяabcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.\-'&?@]*$/;

      // Validate both the outside and all inside parts for (), [], and ##
      const isOutsideValid = outsideRegex.test(
        outsideParenthesesBracketsHashes
      );
      const areInsideParenthesesValid = insideParentheses.every((part) =>
        insideParenthesesRegex.test(part.slice(1, -1))
      );
      const areInsideBracketsValid = insideBrackets.every((part) =>
        insideBracketsRegex.test(part.slice(1, -1))
      );
      const areInsideHashesValid = insideHashes.every((part) =>
        insideHashesRegex.test(part.slice(1, -1))
      );

      return (
        isOutsideValid &&
        areInsideParenthesesValid &&
        areInsideBracketsValid &&
        areInsideHashesValid
      );
    },
    checkEmptySymbols(text) {
      // Extract all content inside hashes: `#text#` or `#    #`
      const hashMatches = text.match(/#(.*?)#/g) || [];

      // Check if any hash pair is empty or contains only whitespace
      const hasEmptyHashes = hashMatches.some((hash) => {
        // Remove the surrounding # characters and check if the content is empty or whitespace
        const contentInsideHash = hash.slice(1, -1).trim();
        return contentInsideHash === "";
      });

      // Check for empty parentheses and brackets
      const emptyParentheses = /\(\s*\)/g.test(text); // Empty ()
      const emptyBrackets = /\[\s*\]/g.test(text); // Empty []

      // Return true if any of the conditions are met (empty pairs)
      return emptyParentheses || emptyBrackets || hasEmptyHashes;
    },

    insertFormData({ text, skip }) {
      this.form = { text, skip };
    },
    resetFormData() {
      this.form = {
        text: "",
        skip: false,
        lineIndex: -1,
      };
    },
    saveChat() {
      this.$emit("save:chat", this.form.text, this.form.skip, this.lineIndex);
    },
    handleKeydown(event) {
      if (!event.shiftKey && event.keyCode === 13) {
        event.preventDefault();
        this.saveChat();
      }
    },
    handleSave(event) {
      // Check if both Control and S keys are pressed
      if (event.ctrlKey && event.code === "KeyS") {
        event.preventDefault();
        this.saveChat();
      }
    },
    handleSkip(event) {
      // Check if Shift and V keys are pressed
      if (event.shiftKey && event.code === "KeyV") {
        event.preventDefault();
        this.form.skip = !this.form.skip;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
