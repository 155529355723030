<template>
  <div class="">
    <div class="flex items-center flex-wrap gap-3 mb-3">
      <el-select
        :model-value="activeTab"
        @change="(val) => $emit('update:active-tab', val)"
        class=""
        size="large"
        style="width: 150px"
      >
        <el-option label="Оператор" value="operator"></el-option>
        <el-option label="Клиент" value="client"></el-option>
      </el-select>
      <div class="button rounded text-lg font-light w-24">
        {{ timeNormalizer(cue) }}
      </div>
      <div class="button" @click="playPause">
        <div v-if="isPlaying">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style="height: 20px; width: 20px"
            viewBox="0 0 24 24"
            fill="transparent"
          >
            <circle cx="12" cy="12" r="10" stroke="black" stroke-width="1.5" />
            <path
              d="M8 9.5C8 9.03406 8 8.80109 8.07612 8.61732C8.17761 8.37229 8.37229 8.17761 8.61732 8.07612C8.80109 8 9.03406 8 9.5 8C9.96594 8 10.1989 8 10.3827 8.07612C10.6277 8.17761 10.8224 8.37229 10.9239 8.61732C11 8.80109 11 9.03406 11 9.5V14.5C11 14.9659 11 15.1989 10.9239 15.3827C10.8224 15.6277 10.6277 15.8224 10.3827 15.9239C10.1989 16 9.96594 16 9.5 16C9.03406 16 8.80109 16 8.61732 15.9239C8.37229 15.8224 8.17761 15.6277 8.07612 15.3827C8 15.1989 8 14.9659 8 14.5V9.5Z"
              stroke="black"
              stroke-width="1.5"
            />
            <path
              d="M13 9.5C13 9.03406 13 8.80109 13.0761 8.61732C13.1776 8.37229 13.3723 8.17761 13.6173 8.07612C13.8011 8 14.0341 8 14.5 8C14.9659 8 15.1989 8 15.3827 8.07612C15.6277 8.17761 15.8224 8.37229 15.9239 8.61732C16 8.80109 16 9.03406 16 9.5V14.5C16 14.9659 16 15.1989 15.9239 15.3827C15.8224 15.6277 15.6277 15.8224 15.3827 15.9239C15.1989 16 14.9659 16 14.5 16C14.0341 16 13.8011 16 13.6173 15.9239C13.3723 15.8224 13.1776 15.6277 13.0761 15.3827C13 15.1989 13 14.9659 13 14.5V9.5Z"
              stroke="black"
              stroke-width="1.5"
            />
          </svg>
        </div>
        <div v-else>
          <div
            style="height: 20px; width: 20px"
            class="flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="height: 12px; width: 12px"
              viewBox="0 0 24 24"
              fill="black"
            >
              <path
                d="M20.4086 9.35258C22.5305 10.5065 22.5305 13.4935 20.4086 14.6474L7.59662 21.6145C5.53435 22.736 3 21.2763 3 18.9671L3 5.0329C3 2.72368 5.53435 1.26402 7.59661 2.38548L20.4086 9.35258Z"
                stroke="black"
                stroke-width="1.5"
              />
            </svg>
          </div>
        </div>
      </div>

      <div
        @click="changeScale(idx / 2)"
        class="button"
        :class="{ 'text-gray-400': scale === 1 }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style="height: 20px; width: 20px"
          viewBox="0 0 24 24"
          fill="transparent"
        >
          <path
            d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
            stroke="black"
            stroke-width="1.5"
          />
          <path
            d="M15 12H9"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </div>

      <div
        @click="changeScale(idx * 2)"
        class="button"
        :class="{ 'text-gray-400': scale === 32 }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style="height: 20px; width: 20px"
          viewBox="0 0 24 24"
          fill="transparent"
        >
          <path
            d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
            stroke="black"
            stroke-width="1.5"
          />
          <path
            d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </div>

      <div
        @click="back"
        class="button"
        :class="{ 'text-gray-400': scale === 32 }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style="height: 20px; width: 20px"
          viewBox="0 0 24 24"
          fill="transparent"
        >
          <path
            d="M15 5L9 12L15 19"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        @click="forward"
        class="button"
        :class="{ 'text-gray-400': scale === 32 }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style="height: 20px; width: 20px"
          viewBox="0 0 24 24"
          fill="transparent"
        >
          <path
            d="M9 5L15 12L9 19"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <el-dropdown class="button" @command="setSpeed" trigger="click">
        <span
          class="el-dropdown-link flex items-center text-black text-xs font-semibold"
        >
          <span>Скорость: {{ speed }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style="height: 16px; width: 20px"
            viewBox="0 0 24 24"
            fill="transparent"
          >
            <path
              d="M19 9L12 15L5 9"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="(value, index) in speeds"
              :key="index"
              :command="value"
            >
              {{ value }}x
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <div v-if="timeEnd - timeStart" class="button text-xs">
        <div class="font-semibold mr-3">Выделен:</div>
        <div class="flex text-center text-gray-400 font-light">
          <div class="mr-2 text-gray-400">
            Начало <br />
            <span class="text-blue-500">{{ timeNormalizer(timeStart) }}</span>
          </div>
          <div class="mr-2">
            Конец <br />
            <span class="text-blue-500">{{ timeNormalizer(timeEnd) }}</span>
          </div>
          <div class="mr-3">
            Длина <br />
            <span class="text-blue-500"
              >{{ Math.round(timeEnd - timeStart) }}с</span
            >
          </div>
          <div class="mr-3">
            <el-tooltip placement="top" :content="'Максимальная длина'">
              Макс. длина</el-tooltip
            ><br /><span class="text-blue-500">15c</span>
          </div>
        </div>
        <el-button
          @click="$emit('resetSelection')"
          size="mini"
          plain
          class="font-light"
          >Очистить</el-button
        >
      </div>
    </div>

    <div class="flex flex-col gap-y-2.5">
      <div ref="default-width" class="overflow-x-scroll">
        <div
          v-bind:style="{
            width: `${width}px`,
          }"
          class="time-scale h-10 relative flex justify-between border-t"
        >
          <div
            v-for="(d, index) in new Array(parseInt(duration) + 1)"
            :key="index"
            class="bg-gray-300 h-4 relative"
            :class="{ 'h-6 bg-gray-400': index % timeScaleInterval === 0 }"
            style="width: 1px"
          >
            <span
              v-if="index % timeScaleInterval === 0"
              class="absolute text-gray-500 font-light text-xs top-6 -left-1"
              >{{ index }}</span
            >
          </div>
        </div>
        <div
          class="wf border"
          ref="wf"
          @mousedown="onMouseDown"
          @mouseup="onMouseUp"
          @mousemove="onMouseMove"
          style="display: inline-block"
          v-bind:style="{
            width: `${width}px`,
            height: `${height}px`,
          }"
        >
          <img
            class="w-full"
            :style="{ height: `${height}px` }"
            :src="audioImage"
          />
          <canvas
            :width="width"
            :height="height"
            class="absolute top-0 left-0"
            ref="canvas"
          ></canvas>
        </div>
      </div>

      <audio
        ref="audio"
        @timeupdate="onTimeUpdate"
        :src="`${audioSrc}`"
      ></audio>
    </div>
  </div>
</template>

<script>
import { defineFileUrl } from "@/services/defineFileUrl";
export default {
  emits: [
    "changeCue",
    "setTime",
    "setIntervals",
    "activeInterval",
    "resetSelection",
    "update:active-tab",
  ],
  props: {
    oper_png: {
      type: String,
      required: true,
    },
    user_png: {
      type: String,
      required: true,
    },
    op_url: {
      type: String,
      required: true,
    },
    user_url: {
      type: String,
      required: true,
    },
    fileHash: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    scope: {
      type: String,
      required: true,
    },
    wide: {
      type: String,
    },
    cue: {
      type: Number,
      default: 0,
    },
    timeStart: {
      type: Number,
      default: 0,
    },
    timeEnd: {
      type: Number,
      default: 0,
    },
    activeInterval: {
      type: Number,
      default: -1,
    },
    lines: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      defaultIdx: 1,
      idx: 1,
      width: 0,
      height: 200,

      mouse: {
        pressed: false,
        x: 0,
        downX: 0,
      },

      isPlaying: false,

      speed: "1x",
      speeds: [0.5, 0.6, 0.75, 1, 1.25, 1.5, 1.75, 2],
    };
  },

  computed: {
    scale() {
      return Math.round(this.idx / this.defaultIdx);
    },
    MEDIA_DOMAIN() {
      return "https://as.vlx.kz";
    },
    audioSrc() {
      const audios = defineFileUrl(this.op_url, this.user_url);
      return audios[this.scope];
    },
    audioImage() {
      if (this.activeTab === "operator") {
        return `${this.MEDIA_DOMAIN}${this.oper_png}`;
      } else if (this.activeTab === "client") {
        return `${this.MEDIA_DOMAIN}${this.user_png}`;
      }
      return "";
    },
    cuePos() {
      return (this.cue / this.duration) * this.width;
    },
    startPos() {
      return (this.timeStart / this.duration) * this.width;
    },
    endPos() {
      return (this.timeEnd / this.duration) * this.width;
    },
    selectionWidth() {
      return this.endPos - this.startPos;
    },
    intervals() {
      let dur = this.duration;
      let width = this.width;

      return this.lines.map(function (v) {
        let leftP = (v.start / dur) * width;
        let widthP = ((v.end - v.start) / dur) * width;

        return {
          left: leftP,
          right: leftP + widthP,
          width: widthP,
        };
      });
    },
    timeScaleInterval() {
      const x = this.width / this.duration;
      if (x > 0 && x < 6) {
        return 10;
      } else if (x < 20) {
        return 5;
      }
      return 1;
    },
  },

  watch: {
    idx() {
      this.width = this.duration * this.idx;
      this.setIntervals();
    },
    cue() {
      const diff =
        this.cuePos -
        this.$refs["default-width"].scrollLeft -
        this.$refs["default-width"].clientWidth +
        10;
      if (diff > 0) {
        this.$refs["default-width"].scrollLeft += diff;
      }
    },
  },

  mounted() {
    window.addEventListener("keydown", this.handleSpacebar);
    this.$refs.audio.onloadeddata = () => {
      this.$emit("setIntervals");
    };

    const clientWidth = this.$refs["default-width"].clientWidth - 2;
    if (clientWidth > this.duration * this.idx) {
      const scale = clientWidth / this.duration;
      this.idx = scale;
      this.defaultIdx = scale;
    }
    this.width = clientWidth;
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleSpacebar);
  },

  methods: {
    changeScale(val) {
      if (val >= this.defaultIdx && val <= this.defaultIdx * 32) {
        this.idx = val;
      }
    },
    timeNormalizer(timeInSeconds) {
      var pad = function (num, size) {
          return ("000" + num).slice(size * -1);
        },
        time = parseFloat(timeInSeconds).toFixed(3),
        hours = Math.floor(time / 60 / 60),
        minutes = Math.floor(time / 60) % 60,
        seconds = Math.floor(time - minutes * 60),
        milliseconds = time.slice(-3);

      if (timeInSeconds >= 3600) {
        return (
          pad(hours, 2) +
          ":" +
          pad(minutes, 2) +
          ":" +
          pad(seconds, 2) +
          "," +
          pad(milliseconds, 2)
        );
      }
      return (
        pad(minutes, 2) + ":" + pad(seconds, 2) + "," + pad(milliseconds, 2)
      );
    },
    onTimeUpdate() {
      let currentTime = this.$refs.audio.currentTime;
      this.$emit("changeCue", currentTime);

      if (this.selectionWidth > 0 && currentTime >= this.timeEnd) {
        this.setCurrentTime(this.timeStart);
      }

      let isInterval = false;
      for (let i in this.intervals) {
        if (
          this.cuePos > this.intervals[i].left &&
          this.cuePos < this.intervals[i].right
        ) {
          this.$emit("activeInterval", i);
          isInterval = true;
          break;
        }
      }

      if (!isInterval) this.$emit("activeInterval", -1);

      window.requestAnimationFrame(this.drawCanvas);
    },

    setCurrentTime(time) {
      this.$refs.audio.currentTime = time;
      this.$emit("changeCue", time);
    },

    onMouseDown(e) {
      this.mouse.downX = e.layerX;
      this.mouse.pressed = true;

      let timeStart = (this.mouse.downX / this.width) * this.duration;

      this.$emit("setTime", {
        timeStart: timeStart,
        timeEnd: timeStart,
      });
      this.setCurrentTime(timeStart);
    },

    onMouseUp() {
      // var relX = e.pageX - this.$refs.wf.offsetLeft;

      if (this.selectionWidth > 2) {
        for (let interval of this.intervals) {
          let left = (interval.left * this.duration) / this.width;
          let right = (interval.right * this.duration) / this.width;

          if (
            this.timeStart >= left &&
            this.timeStart < right &&
            this.timeEnd > right
          ) {
            this.$emit("setTime", {
              timeStart: right,
              timeEnd: this.timeEnd,
            });
            this.setCurrentTime(right);
          }

          if (this.timeStart < left && this.timeEnd > left) {
            this.$emit("setTime", {
              timeStart: this.timeStart,
              timeEnd: left,
            });
            this.setCurrentTime(this.timeStart);
          }

          if (this.timeStart >= left && this.timeEnd <= right) {
            this.$emit("setTime", {
              timeStart: 0,
              timeEnd: 0,
            });
          }
        }
      }

      this.mouse.pressed = false;
      window.requestAnimationFrame(this.drawCanvas);
    },

    onMouseMove(e) {
      if (!this.mouse.pressed) return;

      this.mouse.x = e.layerX;

      let downX = (this.mouse.downX / this.width) * this.duration;
      let x = (this.mouse.x / this.width) * this.duration;

      // const diff =
      //   e.layerX -
      //   this.$refs["default-width"].scrollLeft -
      //   this.$refs["default-width"].clientWidth +
      //   20;
      // if (diff > 0) {
      //   this.$refs["default-width"].scrollLeft += diff;
      //   // x += 10;
      // }

      // console.log(
      //   e.layerX,
      //   this.$refs["default-width"].scrollLeft,
      //   this.mouse.x
      // );

      if (this.mouse.x > this.mouse.downX) {
        this.$emit("setTime", {
          timeStart: downX,
          timeEnd: x,
        });
        this.setCurrentTime(downX);
      } else {
        this.$emit("setTime", {
          timeStart: x,
          timeEnd: downX,
        });
        this.setCurrentTime(x);
      }

      if (this.selectionWidth > 3) {
        window.requestAnimationFrame(this.drawCanvas);
      }
    },

    back() {
      this.$refs["default-width"].scrollTo(0, 0);
    },
    forward() {
      this.$refs["default-width"].scrollTo(this.width, 0);
    },

    playPause() {
      this.isPlaying = !this.$refs.audio.paused;
      if (this.isPlaying) {
        this.$refs.audio.pause();
      } else {
        this.$refs.audio.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    handleSpacebar(event) {
      if (event.code === "Space" && event.shiftKey) {
        event.preventDefault();
        this.playPause(); // Run your play/pause logic here
      }
    },
    setSpeed(speed) {
      this.speed = `${speed}x`;
      this.$refs.audio.playbackRate = speed;
    },

    setIntervals() {
      window.requestAnimationFrame(this.drawCanvas);
    },

    drawCanvas() {
      var ctx = this.$refs.canvas.getContext("2d");

      ctx.clearRect(0, 0, this.width, this.height);

      if (this.selectionWidth) {
        ctx.fillStyle = "rgba(0, 200, 20, 0.4)";
        ctx.fillRect(this.startPos, 0, this.selectionWidth, this.height);
      }

      for (let i = 0; i < this.intervals.length; i++) {
        const interval = this.intervals[i];
        if (this.activeInterval === i) {
          ctx.fillStyle = "rgba(245, 158, 11, 0.4)";
        } else {
          ctx.fillStyle = "rgba(127, 127, 127, 0.4)";
        }
        ctx.fillRect(interval.left, 0, interval.width, this.height);

        ctx.strokeStyle = "rgba(64, 158, 255,1)";
        ctx.beginPath();
        ctx.moveTo(interval.left, 0);
        ctx.setLineDash([4]);
        ctx.lineTo(interval.left, this.height);
        ctx.moveTo(interval.right, 0);
        ctx.lineTo(interval.right, this.height);
        ctx.stroke();
      }

      ctx.setLineDash([]);
      ctx.strokeStyle = "rgba(255, 50, 25, 1)";
      ctx.lineWeight = 5;

      ctx.beginPath();
      ctx.moveTo(this.cuePos, 0);
      ctx.lineTo(this.cuePos, this.height);
      ctx.stroke();
    },
  },
};
</script>

<style scoped>
.button {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13);
  min-width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0 15px;
  cursor: pointer;
}
</style>
