<template>
  <div class="flex flex-col">
    <FullWaveFormVisualizer
      ref="wf"
      :file-id="fileId"
      :cue="cue"
      :time-start="timeStart"
      :time-end="timeEnd"
      :activeInterval="activeInterval"
      :duration="duration"
      :lines="FullChat"
      @setIntervals="setIntervals"
      @changeCue="(val) => (cue = val)"
      @activeInterval="(val) => (activeInterval = parseInt(val))"
      @set-time="setTime"
      @resetSelection="resetSelection"
      @previous-fragment="previousFragment"
      @next-fragment="nextFragment"
    />
    <div v-if="state !== 0" class="mt-12">
      <div>
        <div class="flex flex-col">
          <div class="flex w-full justify-center">
            <FillingFormFullAudio
              v-show="!payedAudio"
              ref="filling-form"
              @save:chat="saveChat"
              :lang="lang"
              :line-index="startAudio"
              :lines="FullChat"
              :rule_for_symbols="rule_for_symbols"
            />
          </div>
          <div class="w-full flex justify-end">
            <div>
              <!--              <div-->
              <!--                v-if="-->
              <!--                  user.scopes.includes('admin') ||-->
              <!--                  user.scopes.includes('verifier')-->
              <!--                "-->
              <!--                style="width: 400px"-->
              <!--                class="bg-gray-50 border border-solid border-gray-200 rounded-lg flex px-5 py-3 justify-between mt-5 items-start"-->
              <!--              >-->
              <!--                <div class="flex">-->
              <!--                  <div>-->
              <!--                    <p class="text-gray-800">Для модели</p>-->
              <!--                    <p-->
              <!--                      class="mt-1 mb-0 leading-4 text-gray-500"-->
              <!--                      style="font-size: 11px"-->
              <!--                    >-->
              <!--                      Данный файл будет использоваться для эталонной модели-->
              <!--                      распознования речи.-->
              <!--                    </p>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <el-switch-->
              <!--                  @click="changeTagForModel"-->
              <!--                  v-model="is_model"-->
              <!--                  :inactive-value="0"-->
              <!--                  :active-value="1"-->
              <!--                ></el-switch>-->
              <!--              </div>-->
              <div
                @click="showInform = !showInform"
                style="width: 400px"
                class="bg-gray-50 border border-solid border-gray-200 rounded-lg flex px-5 py-2 mt-5 justify-between items-center cursor-pointer"
              >
                <p class="mt-0 text-gray-800">Правила разметки</p>
                <svg
                  class="ov-icon"
                  aria-hidden="true"
                  width="28"
                  height="28"
                  viewBox="-1.6 -1.6 19.2 19.2"
                  fill="rgba(31, 41, 55)"
                  style="font-size: 2.88em"
                >
                  <path
                    d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
                  ></path>
                  <path
                    d="M5.255 5.786a.237.237 0 00.241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 00.25.246h.811a.25.25 0 00.25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <!--        <el-col :xs="24" :sm="24" :md="11" :lg="15" :offset="1">-->
        <!--          <ul-->
        <!--            style="height: calc(100vh - 480px - 3rem); overflow-y: auto"-->
        <!--            class="bg-white"-->
        <!--            v-if="FullChat && FullChat.length > 0"-->
        <!--          >-->
        <!--            <li-->
        <!--              v-for="(message, idx) in FullChat"-->
        <!--              :key="idx"-->
        <!--              @click="selectLine(message.start, message.end, idx)"-->
        <!--              :class="[activeInterval === idx ? 'bg-yellow-100' : '']"-->
        <!--              class="flex items-center p-2.5 rounded leading-none mb-1 relative cursor-pointer hover:bg-gray-100"-->
        <!--            >-->
        <!--              <div class="flex flex-col">-->
        <!--                <div class="flex items-center">-->
        <!--                  <div class="text-gray-600 mr-1.5">-->
        <!--                    <svg-->
        <!--                      style="width: 14px; height: 14px"-->
        <!--                      xmlns="http://www.w3.org/2000/svg"-->
        <!--                      viewBox="0 0 24 24"-->
        <!--                      fill="none"-->
        <!--                    >-->
        <!--                      <circle-->
        <!--                        cx="12"-->
        <!--                        cy="12"-->
        <!--                        r="10"-->
        <!--                        stroke="rgba(75, 85, 99)"-->
        <!--                        stroke-width="1.5"-->
        <!--                      />-->
        <!--                      <path-->
        <!--                        d="M12 8V12L14.5 14.5"-->
        <!--                        stroke="rgba(75, 85, 99)"-->
        <!--                        stroke-width="1.5"-->
        <!--                        stroke-linecap="round"-->
        <!--                        stroke-linejoin="round"-->
        <!--                      />-->
        <!--                    </svg>-->
        <!--                  </div>-->
        <!--                  <div class="text-gray-400 text-xs">-->
        <!--                    <span>{{ timeNormalizer(message.start) }}</span> - -->
        <!--                    <span>{{ timeNormalizer(message.end) }}</span>-->
        <!--                  </div>-->
        <!--                  <div-->
        <!--                    v-if="message.channel === 'op'"-->
        <!--                    class="rounded text-white text-xs px-1 ml-2 bg-blue-400"-->
        <!--                  >-->
        <!--                    Оператор-->
        <!--                  </div>-->
        <!--                  <div-->
        <!--                    v-else-if="message.channel === 'user'"-->
        <!--                    class="rounded text-white text-xs px-1 ml-2 bg-yellow-400"-->
        <!--                  >-->
        <!--                    Клиент-->
        <!--                  </div>-->
        <!--                  <span-->
        <!--                    v-if="message.skip"-->
        <!--                    class="bg-gray-400 rounded text-white text-xs px-1 ml-2"-->
        <!--                    >Пропустить</span-->
        <!--                  >-->
        <!--                </div>-->
        <!--                <div class="text-gray-800 mt-1">{{ message.text }}</div>-->
        <!--              </div>-->
        <!--              <div class="flex-shrink-0 ml-auto">-->
        <!--                <button-->
        <!--                  class="text-gray-300 hover:text-gray-700"-->
        <!--                  @click.stop="deleteInterval(idx)"-->
        <!--                >-->
        <!--                  <svg-->
        <!--                    xmlns="http://www.w3.org/2000/svg"-->
        <!--                    width="16px"-->
        <!--                    height="16px"-->
        <!--                    viewBox="0 0 24 24"-->
        <!--                    class="text-red-500"-->
        <!--                    fill="none"-->
        <!--                  >-->
        <!--                    <path-->
        <!--                      d="M20.5001 6H3.5"-->
        <!--                      stroke="currentColor"-->
        <!--                      stroke-width="1.5"-->
        <!--                      stroke-linecap="round"-->
        <!--                    />-->
        <!--                    <path-->
        <!--                      d="M18.8332 8.5L18.3732 15.3991C18.1962 18.054 18.1077 19.3815 17.2427 20.1907C16.3777 21 15.0473 21 12.3865 21H11.6132C8.95235 21 7.62195 21 6.75694 20.1907C5.89194 19.3815 5.80344 18.054 5.62644 15.3991L5.1665 8.5"-->
        <!--                      stroke="currentColor"-->
        <!--                      stroke-width="1.5"-->
        <!--                      stroke-linecap="round"-->
        <!--                    />-->
        <!--                    <path-->
        <!--                      d="M9.5 11L10 16"-->
        <!--                      stroke="currentColor"-->
        <!--                      stroke-width="1.5"-->
        <!--                      stroke-linecap="round"-->
        <!--                    />-->
        <!--                    <path-->
        <!--                      d="M14.5 11L14 16"-->
        <!--                      stroke="currentColor"-->
        <!--                      stroke-width="1.5"-->
        <!--                      stroke-linecap="round"-->
        <!--                    />-->
        <!--                    <path-->
        <!--                      d="M6.5 6C6.55588 6 6.58382 6 6.60915 5.99936C7.43259 5.97849 8.15902 5.45491 8.43922 4.68032C8.44784 4.65649 8.45667 4.62999 8.47434 4.57697L8.57143 4.28571C8.65431 4.03708 8.69575 3.91276 8.75071 3.8072C8.97001 3.38607 9.37574 3.09364 9.84461 3.01877C9.96213 3 10.0932 3 10.3553 3H13.6447C13.9068 3 14.0379 3 14.1554 3.01877C14.6243 3.09364 15.03 3.38607 15.2493 3.8072C15.3043 3.91276 15.3457 4.03708 15.4286 4.28571L15.5257 4.57697C15.5433 4.62992 15.5522 4.65651 15.5608 4.68032C15.841 5.45491 16.5674 5.97849 17.3909 5.99936C17.4162 6 17.4441 6 17.5 6"-->
        <!--                      stroke="currentColor"-->
        <!--                      stroke-width="1.5"-->
        <!--                    />-->
        <!--                  </svg>-->
        <!--                </button>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--          </ul>-->

        <!--          <div-->
        <!--            v-if="FullChat && FullChat.length && !payedAudio"-->
        <!--            class="text-right mt-2"-->
        <!--          >-->
        <!--            <el-button type="primary" plain @click="save">Сохранить</el-button>-->
        <!--          </div>-->
        <!--        </el-col>-->
      </div>
    </div>
    <el-dialog
      v-model="showInform"
      top="20px"
      width="90%"
      custom-class="rounded-xl"
    >
      <template #title>Правила заполнения</template>
      <el-table :data="rules">
        <el-table-column label="Пример" prop="example"></el-table-column>
        <el-table-column
          label="Правильная разметка"
          prop="correct_markup"
        ></el-table-column>
        <el-table-column label="Примечание" prop="footnote"></el-table-column>
        <el-table-column
          width="80px"
          label="Язык"
          prop="lang"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import FullWaveFormVisualizer from "@/views/files/shared/components/FullWaveFormVisualizer.vue";
import { getTranscript, saveTranscript, tagForModel } from "@/api/files";
import { getRules } from "@/api/rules";
import FillingFormFullAudio from "@/views/files/shared/components/FillingFormFullAudio.vue";
export default {
  emits: ["update:active-tab"],
  components: {
    FillingFormFullAudio,
    FullWaveFormVisualizer,
  },

  props: {
    fileId: {
      type: String,
      required: true,
    },
    meta: {
      type: Object,
    },
    lang: {
      type: String,
      required: true,
    },
    savedLines: {
      type: Array,
      default: () => [],
    },
    duration: {
      type: Number,
      default: 0,
    },
    rule_for_symbols: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      startAudio: 1,
      cue: 0,
      activeInterval: -1,
      activeTextField: null,
      intervalId: null,
      autoSaveRunning: false,

      timeStart: 0,
      timeEnd: 0,
      editingLineIndex: null,
      is_model: 0,
      showInform: false,
      changeModelTypeLoading: false,
      FullChat: [],
      rules: [],
      keysPressed: {
        ctrl: false,
        alt: false,
        s: false,
      },
      HotKeySaveTriggered: false,
    };
  },

  computed: {
    state() {
      return parseInt(this.$route.params.state);
    },
    user() {
      return this.$store.state.user.user;
    },
    payedAudio() {
      return this.state === 5;
    },
  },
  watch: {
    FullChat: {
      immediate: true,
      handler(newLines) {
        if (newLines && newLines.length > 0) {
          this.cue = newLines[0].start;
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      let file = { ...this.meta };
      this.is_model = file.is_model;
    }, 300);
    this.getFullChat();
    this.getRules();
    // this.startAutoSave();
  },
  // beforeUnmount() {
  //   this.stopAutoSave();
  // },
  methods: {
    async getFullChat() {
      try {
        const transcript = await getTranscript(this.fileId);
        this.FullChat = transcript.all || transcript.all_utterances || [];
      } catch (error) {
        console.log(error);
      }
    },
    async getRules() {
      let data = await getRules();
      console.log(data);
      this.rules = data.data;
    },
    async changeTagForModel() {
      this.changeModelTypeLoading = true;
      let action = "unmarked";
      if (this.is_model) {
        action = "marked";
      }
      let data = await tagForModel(this.fileId, action);
      if (data._success) {
        this.$emit("fileMarked", data._success);
      }
      console.log(this.is_model);
      this.changeModelTypeLoading = false;
    },
    saveChat(text, skip, index) {
      // Update only the `text` property of the object at the given index
      const i = index - 1;
      this.FullChat[i].text = text || "";
      this.FullChat[i].skip = skip;

      // Save changes if needed
      this.save();
    },
    // addLine({ text, skip }) {
    //   if (this.timeEnd - this.timeStart > 0) {
    //     let i = 0;
    //     for (i = 0; i < this.FullChat.length; i++) {
    //       if (this.FullChat[i].start >= this.timeEnd) break;
    //     }
    //
    //     this.FullChat.splice(i, 0, {
    //       start: this.timeStart,
    //       end: this.timeEnd,
    //       text,
    //       skip,
    //     });
    //
    //     this.$refs["filling-form"].resetFormData();
    //     this.setIntervals();
    //   }
    //   this.resetSelection();
    // },

    // updateLine({ text, skip, lineIndex }) {
    //   this.FullChat[lineIndex] = {
    //     ...this.FullChat[lineIndex],
    //     text,
    //     skip,
    //   };
    //   this.$refs["filling-form"].resetFormData();
    // },

    setTime(payload) {
      if (payload.timeEnd - payload.timeStart > 15) {
        payload.timeEnd = payload.timeStart + 15;
      } else if (payload.timeEnd - payload.timeStart < 0.5) {
        payload.timeEnd = 0;
        payload.timeStart = 0;
      }

      const startTime =
        Math.round(payload.timeStart * 100 + Number.EPSILON) / 100;
      this.timeStart = startTime;

      Math.round(payload.timeStart * 100 + Number.EPSILON) / 100;
      this.timeEnd = Math.round(payload.timeEnd * 100 + Number.EPSILON) / 100;

      if (this.timeEnd - this.timeStart > 2) {
        this.$nextTick(() => {
          this.$refs["filling-form"].resetFormData();
          this.$refs["filling-form"].$refs["transcribe-input"].focus();
        });
      }
    },

    selectLine(start, end, idx) {
      this.activeTextField = idx;
      this.timeStart = start;
      this.timeEnd = end;
      this.$refs.wf.setCurrentTime(start);
      this.setIntervals();

      for (let i = 0; i < this.FullChat.length; i++) {
        if (this.FullChat[i].start === start && this.FullChat[i].end === end) {
          this.editIntervalText(i);
          break;
        }
      }
    },
    previousFragment(index) {
      this.startAudio = index;
      const i = index - 1;
      this.editIntervalText(i);
    },
    nextFragment(index) {
      this.startAudio = index;
      const i = index - 1;
      this.editIntervalText(i);
    },

    timeNormalizer(timeInSeconds) {
      var pad = function (num, size) {
          return ("000" + num).slice(size * -1);
        },
        time = parseFloat(timeInSeconds).toFixed(3),
        hours = Math.floor(time / 60 / 60),
        minutes = Math.floor(time / 60) % 60,
        seconds = Math.floor(time - minutes * 60),
        milliseconds = time.slice(-3);
      if (timeInSeconds >= 3600) {
        return (
          pad(hours, 2) +
          ":" +
          pad(minutes, 2) +
          ":" +
          pad(seconds, 2) +
          "," +
          pad(milliseconds, 2)
        );
      }
      return (
        pad(minutes, 2) + ":" + pad(seconds, 2) + "," + pad(milliseconds, 2)
      );
    },

    async save() {
      await saveTranscript(this.fileId, {
        lines: this.FullChat,
        channel: "all",
      });
      this.$emit("save:changedLines", {
        payload: this.FullChat,
      });
    },
    // startAutoSave() {
    //   if (!this.autoSaveRunning) {
    //     this.autoSaveRunning = true;
    //     this.intervalId = setInterval(() => {
    //       store.dispatch("notify/add", {
    //         type: "info",
    //         message: "Автосохранение",
    //         size: "mini",
    //       });
    //       this.save();
    //     }, 30000);
    //   }
    // },
    // stopAutoSave() {
    //   if (this.intervalId) {
    //     clearInterval(this.intervalId);
    //     this.intervalId = null;
    //     this.autoSaveRunning = false;
    //   }
    // },

    resetSelection() {
      this.timeStart = 0;
      this.timeEnd = 0;
      this.setIntervals();
    },

    setIntervals() {
      console.log("set intervaals");
      this.$refs.wf.setIntervals(this.FullChat);
    },

    editIntervalText(i) {
      // this.editingLineIndex = i;
      this.$refs["filling-form"].insertFormData({
        ...this.FullChat[i],
      });
    },

    deleteInterval(i) {
      this.FullChat.splice(i, 1);
      this.setIntervals();
    },
  },
};
</script>

<style></style>
