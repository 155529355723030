<template>
  <div class="">
    <div class="flex items-center justify-center w-full mt-10">
      <div class="text-lg">Фрагмент # {{ audioStart }}/{{ lines.length }}</div>
    </div>

    <div class="flex flex-col gap-y-2.5 mt-5">
      <div ref="default-width" class="overflow-x-hidden">
        <div
          v-bind:style="{
            width: `${width}px`,
          }"
          class="time-scale h-10 relative flex justify-between border-t"
        >
          <div
            v-for="(d, index) in new Array(parseInt(duration) + 1)"
            :key="index"
            class="bg-gray-300 h-4 relative"
            :class="{ 'h-10 bg-gray-400': index % timeScaleInterval === 0 }"
            style="width: 1px"
          >
            <!--            <span-->
            <!--              v-if="index % timeScaleInterval === 0"-->
            <!--              class="absolute text-gray-500 font-light text-xs top-6 -left-1"-->
            <!--              >{{ index }}</span-->
            <!--            >-->
          </div>
        </div>
        <div
          class="wf border w-full bg-gray-50"
          ref="wf"
          style="display: inline-block"
          v-bind:style="{
            width: `${width}px`,
            height: `${height}px`,
          }"
        >
          <div
            v-for="(line, index) in lines"
            :key="index"
            class="absolute left-1/2 w-full"
            v-show="audioStart === index + 1"
            :style="{
              transform: `translateX(-50%) scaleX(4)`,
              width: `${fragmentWidth(line)}px`,
              height: `${height}px`,
              backgroundImage: `url(${audioImage})`,
              backgroundPosition: `-${fragmentPosition(line)}px 0`,
              backgroundSize: `${width}px ${height}px`,
            }"
          >
            <div
              v-if="audioStart === index + 1"
              class="progress-bar"
              :style="{
                width: `${playbackProgress(line)}%`,
                height: '100%',
                backgroundColor: 'rgba(99,99,99,0.5)' /* Highlight color */,
                position: 'absolute',
                top: 0,
                left: 0,
              }"
            ></div>
          </div>
        </div>
      </div>
      <div class="flex items-center gap-x-3 w-full justify-center mt-5">
        <div class="button rounded text-lg font-light w-24">
          {{ timeNormalizer(cue) }}
        </div>
        <div class="flex items-center gap-x-3">
          <el-tooltip
            content="Предыдущий фрагмент (Shift + ←)"
            :show-after="300"
          >
            <div class="button" @click="previousFragment">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="height: 20px; width: 20px"
                viewBox="0 0 24 24"
                fill="transparent"
              >
                <path
                  d="M15 5L9 12L15 19"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </el-tooltip>
          <el-tooltip
            content="Воспроизводить и приостанавливать (Shift + Пробел)"
            :show-after="300"
          >
            <div class="button" @click="playPause">
              <div v-if="isPlaying">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="height: 20px; width: 20px"
                  viewBox="0 0 24 24"
                  fill="transparent"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <path
                    d="M8 9.5C8 9.03406 8 8.80109 8.07612 8.61732C8.17761 8.37229 8.37229 8.17761 8.61732 8.07612C8.80109 8 9.03406 8 9.5 8C9.96594 8 10.1989 8 10.3827 8.07612C10.6277 8.17761 10.8224 8.37229 10.9239 8.61732C11 8.80109 11 9.03406 11 9.5V14.5C11 14.9659 11 15.1989 10.9239 15.3827C10.8224 15.6277 10.6277 15.8224 10.3827 15.9239C10.1989 16 9.96594 16 9.5 16C9.03406 16 8.80109 16 8.61732 15.9239C8.37229 15.8224 8.17761 15.6277 8.07612 15.3827C8 15.1989 8 14.9659 8 14.5V9.5Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <path
                    d="M13 9.5C13 9.03406 13 8.80109 13.0761 8.61732C13.1776 8.37229 13.3723 8.17761 13.6173 8.07612C13.8011 8 14.0341 8 14.5 8C14.9659 8 15.1989 8 15.3827 8.07612C15.6277 8.17761 15.8224 8.37229 15.9239 8.61732C16 8.80109 16 9.03406 16 9.5V14.5C16 14.9659 16 15.1989 15.9239 15.3827C15.8224 15.6277 15.6277 15.8224 15.3827 15.9239C15.1989 16 14.9659 16 14.5 16C14.0341 16 13.8011 16 13.6173 15.9239C13.3723 15.8224 13.1776 15.6277 13.0761 15.3827C13 15.1989 13 14.9659 13 14.5V9.5Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <div v-else>
                <div
                  style="height: 20px; width: 20px"
                  class="flex items-center justify-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="height: 12px; width: 12px"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      d="M20.4086 9.35258C22.5305 10.5065 22.5305 13.4935 20.4086 14.6474L7.59662 21.6145C5.53435 22.736 3 21.2763 3 18.9671L3 5.0329C3 2.72368 5.53435 1.26402 7.59661 2.38548L20.4086 9.35258Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </el-tooltip>
          <el-tooltip
            content="Следующий фрагмент (Shift + →)"
            :show-after="300"
          >
            <div class="button" @click="nextFragment">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="height: 20px; width: 20px"
                viewBox="0 0 24 24"
                fill="transparent"
              >
                <path
                  d="M9 5L15 12L9 19"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </el-tooltip>
        </div>
        <el-dropdown class="button" @command="setSpeed" trigger="click">
          <span
            class="el-dropdown-link flex items-center text-xs font-semibold"
          >
            <span>Скорость: {{ speed }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="height: 16px; width: 20px"
              viewBox="0 0 24 24"
              fill="transparent"
            >
              <path
                d="M19 9L12 15L5 9"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <template #dropdown>
            <el-dropdown-menu class="custom-dropdown-menu">
              <el-dropdown-item
                v-for="(value, index) in speeds"
                :key="index"
                :command="value"
              >
                {{ value }}x
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <audio
        ref="audio"
        @timeupdate="onTimeUpdate"
        :src="`${audioSrc}`"
      ></audio>
    </div>
  </div>
</template>

<script>
import apiClient from "@/api/api-client";

export default {
  emits: [
    "changeCue",
    "setTime",
    "activeInterval",
    "resetSelection",
    "update:active-tab",
  ],
  props: {
    fileId: {
      type: String,
    },
    duration: {
      type: Number,
      required: true,
    },
    wide: {
      type: String,
    },
    cue: {
      type: Number,
      default: 0,
    },
    timeStart: {
      type: Number,
      default: 0,
    },
    timeEnd: {
      type: Number,
      default: 0,
    },
    activeInterval: {
      type: Number,
      default: -1,
    },
    lines: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fragments: [],
      activeFragmentIndex: 0,
      audioStart: 1,
      defaultIdx: 1,
      idx: 1,
      width: 0,
      height: 200,

      mouse: {
        pressed: false,
        x: 0,
        downX: 0,
      },

      isPlaying: false,

      speed: "1x",
      speeds: [0.5, 0.6, 0.75, 1, 1.25, 1.5, 1.75, 2],
      audio: [],
    };
  },

  computed: {
    secondWidth() {
      return this.width / this.duration; // Width of each chunk
    },
    currentFragment() {
      return this.lines[this.audioStart - 1]; // Get the current fragment (1-based index)
    },
    scale() {
      return Math.round(this.idx / this.defaultIdx);
    },
    audioURL() {
      return (
        this.audio &&
        this.audio.data &&
        this.audio.data.data &&
        this.audio.data.data.meta &&
        this.audio.data.data.meta.url_hash
      );
    },
    imageURL() {
      return (
        this.audio &&
        this.audio.data &&
        this.audio.data.data &&
        this.audio.data.data.meta &&
        this.audio.data.data.meta.url_hash_png
      );
    },
    MEDIA_DOMAIN() {
      return "https://tr.q19.kz";
    },
    audioSrc() {
      return `${this.MEDIA_DOMAIN}${this.audioURL}`;
    },
    audioImage() {
      return `${this.MEDIA_DOMAIN}${this.imageURL}`;
    },
    cuePos() {
      return (this.cue / this.duration) * this.width;
    },
    startPos() {
      return (this.timeStart / this.duration) * this.width;
    },
    endPos() {
      return (this.timeEnd / this.duration) * this.width;
    },
    selectionWidth() {
      return this.endPos - this.startPos;
    },
    intervals() {
      let dur = this.duration;
      let width = this.width;

      return this.lines.map(function (v) {
        let leftP = (v.start / dur) * width;
        let widthP = ((v.end - v.start) / dur) * width;

        return {
          left: leftP,
          right: leftP + widthP,
          width: widthP,
        };
      });
    },
    timeScaleInterval() {
      const x = this.width / this.duration;
      if (x > 0 && x < 6) {
        return 10;
      } else if (x < 20) {
        return 5;
      }
      return 1;
    },
  },

  watch: {
    timeStart() {
      this.width = this.duration * this.idx;
    },
  },

  mounted() {
    window.addEventListener("keydown", this.handleSpacebar);
    window.addEventListener("keydown", this.handleFragment);
    this.getAudio();
    const clientWidth = this.$refs["default-width"].clientWidth - 2;
    if (clientWidth > this.duration * this.idx) {
      const scale = clientWidth / this.duration;
      this.idx = scale;
      this.defaultIdx = scale;
    }
    this.width = clientWidth;
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleFragment);
    window.removeEventListener("keydown", this.handleSpacebar);
  },

  methods: {
    handleFragment(event) {
      if (event.shiftKey && event.key === "ArrowLeft") {
        event.preventDefault();
        this.previousFragment(); // Call previous fragment method
      } else if (event.shiftKey && event.key === "ArrowRight") {
        event.preventDefault();
        this.nextFragment(); // Call next fragment method
      }
    },
    playbackProgress(line) {
      const currentTime = this.cue; // Current audio time
      const chunkDuration = line.end - line.start; // Duration of this chunk
      const elapsedTime = currentTime - line.start; // Time elapsed in this chunk

      // Calculate progress percentage
      return Math.max(0, Math.min(100, (elapsedTime / chunkDuration) * 100));
    },
    fragmentWidth(line) {
      const duration = line.end - line.start;
      const secondWidth = this.secondWidth;
      return secondWidth * duration;
    },
    fragmentPosition(line) {
      const startPosition = line.start;
      const position = this.secondWidth;
      return startPosition * position;
    },
    previousFragment() {
      // Update the fragment index
      this.audioStart =
        this.audioStart > 1 ? this.audioStart - 1 : this.lines.length;

      // Update the audio start time
      const currentFragment = this.lines[this.audioStart - 1]; // Adjust for zero-based index
      const timeStart = currentFragment.start;
      this.updateAudioTime(timeStart);
      this.$emit("previous-fragment", this.audioStart);
    },
    nextFragment() {
      // Update the fragment index
      this.audioStart =
        this.audioStart < this.lines.length ? this.audioStart + 1 : 1;

      // Update the audio start time
      const currentFragment = this.lines[this.audioStart - 1]; // Adjust for zero-based index
      const timeStart = currentFragment.start;
      this.updateAudioTime(timeStart);
      this.$emit("next-fragment", this.audioStart);
    },
    updateAudioTime(timeStart) {
      // Set the audio's currentTime to the start time of the selected fragment
      const audio = this.$refs.audio;
      audio.currentTime = timeStart;

      // Optionally stop playback if you don't want the audio to auto-play
      audio.pause();

      // Ensure the time display updates
      // this.$emit("setTime", { timeStart: timeStart, timeEnd: timeStart });
    },
    async getAudio() {
      this.audio = (await apiClient.get(`file/${this.fileId}`)) || [];
    },
    // changeScale(val) {
    //   if (val >= this.defaultIdx && val <= this.defaultIdx * 32) {
    //     this.idx = val;
    //   }
    // },
    timeNormalizer(timeInSeconds) {
      var pad = function (num, size) {
          return ("000" + num).slice(size * -1);
        },
        time = parseFloat(timeInSeconds).toFixed(3),
        hours = Math.floor(time / 60 / 60),
        minutes = Math.floor(time / 60) % 60,
        seconds = Math.floor(time - minutes * 60),
        milliseconds = time.slice(-3);

      if (timeInSeconds >= 3600) {
        return (
          pad(hours, 2) +
          ":" +
          pad(minutes, 2) +
          ":" +
          pad(seconds, 2) +
          "," +
          pad(milliseconds, 2)
        );
      }
      return (
        pad(minutes, 2) + ":" + pad(seconds, 2) + "," + pad(milliseconds, 2)
      );
    },
    onTimeUpdate() {
      let currentTime = this.$refs.audio.currentTime;
      this.$emit("changeCue", currentTime);

      if (this.selectionWidth > 0 && currentTime >= this.timeEnd) {
        this.setCurrentTime(this.timeStart);
      }

      let isInterval = false;
      for (let i in this.intervals) {
        if (
          this.cuePos > this.intervals[i].left &&
          this.cuePos < this.intervals[i].right
        ) {
          this.$emit("activeInterval", i);
          isInterval = true;
          break;
        }
      }

      if (!isInterval) this.$emit("activeInterval", -1);

      // window.requestAnimationFrame(this.drawCanvas);
    },

    setCurrentTime(time) {
      this.$refs.audio.currentTime = time;
      this.$emit("changeCue", time);
    },

    // onMouseDown(e) {
    //   this.mouse.downX = e.layerX;
    //   this.mouse.pressed = true;
    //
    //   let timeStart = (this.mouse.downX / this.width) * this.duration;
    //
    //   this.$emit("setTime", {
    //     timeStart: timeStart,
    //     timeEnd: timeStart,
    //   });
    //   this.setCurrentTime(timeStart);
    // },

    // onMouseUp() {
    //   // var relX = e.pageX - this.$refs.wf.offsetLeft;
    //
    //   if (this.selectionWidth > 2) {
    //     for (let interval of this.intervals) {
    //       let left = (interval.left * this.duration) / this.width;
    //       let right = (interval.right * this.duration) / this.width;
    //
    //       if (
    //         this.timeStart >= left &&
    //         this.timeStart < right &&
    //         this.timeEnd > right
    //       ) {
    //         this.$emit("setTime", {
    //           timeStart: right,
    //           timeEnd: this.timeEnd,
    //         });
    //         this.setCurrentTime(right);
    //       }
    //
    //       if (this.timeStart < left && this.timeEnd > left) {
    //         this.$emit("setTime", {
    //           timeStart: this.timeStart,
    //           timeEnd: left,
    //         });
    //         this.setCurrentTime(this.timeStart);
    //       }
    //
    //       if (this.timeStart >= left && this.timeEnd <= right) {
    //         this.$emit("setTime", {
    //           timeStart: 0,
    //           timeEnd: 0,
    //         });
    //       }
    //     }
    //   }
    //
    //   this.mouse.pressed = false;
    //   window.requestAnimationFrame(this.drawCanvas);
    // },
    //
    // onMouseMove(e) {
    //   if (!this.mouse.pressed) return;
    //
    //   this.mouse.x = e.layerX;
    //
    //   let downX = (this.mouse.downX / this.width) * this.duration;
    //   let x = (this.mouse.x / this.width) * this.duration;
    //
    //   // const diff =
    //   //   e.layerX -
    //   //   this.$refs["default-width"].scrollLeft -
    //   //   this.$refs["default-width"].clientWidth +
    //   //   20;
    //   // if (diff > 0) {
    //   //   this.$refs["default-width"].scrollLeft += diff;
    //   //   // x += 10;
    //   // }
    //
    //   // console.log(
    //   //   e.layerX,
    //   //   this.$refs["default-width"].scrollLeft,
    //   //   this.mouse.x
    //   // );
    //
    //   if (this.mouse.x > this.mouse.downX) {
    //     this.$emit("setTime", {
    //       timeStart: downX,
    //       timeEnd: x,
    //     });
    //     this.setCurrentTime(downX);
    //   } else {
    //     this.$emit("setTime", {
    //       timeStart: x,
    //       timeEnd: downX,
    //     });
    //     this.setCurrentTime(x);
    //   }
    //
    //   if (this.selectionWidth > 3) {
    //     window.requestAnimationFrame(this.drawCanvas);
    //   }
    // },

    // back() {
    //   this.$refs["default-width"].scrollTo(0, 0);
    // },
    // forward() {
    //   this.$refs["default-width"].scrollTo(this.width, 0);
    // },

    playPause() {
      const audio = this.$refs.audio;
      const { start, end } = this.currentFragment;

      if (this.isPlaying) {
        // Pause the audio and clear the timer
        audio.pause();
        clearInterval(this.timer);
        this.isPlaying = false;
      } else {
        // Set the start time of the current fragment only if audio is at the start
        if (audio.currentTime < start || audio.currentTime > end) {
          audio.currentTime = start;
        }

        // Play the audio and handle errors
        audio
          .play()
          .then(() => {
            // Set a timer to stop at the fragment's end time
            this.timer = setInterval(() => {
              if (audio.currentTime >= end) {
                audio.pause();
                audio.currentTime = start; // Reset to the fragment's start time
                clearInterval(this.timer); // Clear the interval
                this.isPlaying = false;
              }
            }, 100); // Check playback position every 100ms
          })
          .catch((error) => {
            console.error("Audio playback failed:", error);
          });

        this.isPlaying = true;
      }
    },
    handleSpacebar(event) {
      if (event.code === "Space" && event.shiftKey) {
        event.preventDefault();
        this.playPause(); // Run your play/pause logic here
      }
    },
    setSpeed(speed) {
      this.speed = `${speed}x`;
      this.$refs.audio.playbackRate = speed;
    },

    // setIntervals() {
    //   window.requestAnimationFrame(this.drawCanvas);
    // },
    // drawCanvas() {
    //   const ctx = this.$refs.canvas.getContext("2d");
    //
    //   // Clear the canvas
    //   ctx.clearRect(0, 0, this.width, this.height);
    //
    //   // Draw the selection area if there is one
    //   if (this.selectionWidth) {
    //     ctx.fillStyle = "rgba(0, 200, 20, 0.4)";
    //     ctx.fillRect(this.startPos, 0, this.selectionWidth, this.height);
    //   }
    //
    //   // Draw each interval
    //   for (let i = 0; i < this.intervals.length; i++) {
    //     const interval = this.intervals[i];
    //
    //     // Highlight active interval
    //     ctx.fillStyle =
    //       this.activeInterval === i
    //         ? "rgba(245, 158, 11, 0.4)"
    //         : "rgba(127, 127, 127, 0.4)";
    //     ctx.fillRect(interval.left, 0, interval.width, this.height);
    //
    //     // Dashed boundary lines for intervals
    //     ctx.strokeStyle = "rgba(64, 158, 255, 1)";
    //     ctx.setLineDash([4]); // Dashed line
    //     ctx.beginPath();
    //     ctx.moveTo(interval.left, 0);
    //     ctx.lineTo(interval.left, this.height);
    //     ctx.moveTo(interval.right, 0);
    //     ctx.lineTo(interval.right, this.height);
    //     ctx.stroke();
    //   }
    //
    //   // Reset the line dash style
    //   ctx.setLineDash([]);
    //
    //   // Draw the cue position (vertical red line)
    //   ctx.strokeStyle = "rgba(255, 50, 25, 1)";
    //   ctx.lineWidth = 3;
    //
    //   ctx.beginPath();
    //   ctx.moveTo(this.cuePos, 0);
    //   ctx.lineTo(this.cuePos, this.height);
    //   ctx.stroke();
    //
    //   // Draw the fragments with a blue border only
    //   for (let i = 0; i < this.fragments.length; i++) {
    //     const fragment = this.fragments[i];
    //
    //     // Calculate position and width of each fragment based on its start and end times
    //     const fragmentLeft = (fragment.start / this.duration) * this.width;
    //     const fragmentWidth =
    //       ((fragment.end - fragment.start) / this.duration) * this.width;
    //
    //     // Draw the blue border only (no fill)
    //     ctx.strokeStyle = "rgba(0, 128, 255, 1)"; // Blue border color
    //     ctx.lineWidth = 2; // Border thickness
    //     ctx.strokeRect(fragmentLeft, 0, fragmentWidth, this.height); // Only draw border
    //
    //     // Add the start time label to the fragment
    //     ctx.fillStyle = "black";
    //     ctx.font = "12px Arial";
    //     ctx.fillText(
    //       this.timeNormalizer(fragment.start),
    //       fragmentLeft + 4,
    //       14 // Adjust vertical placement of text
    //     );
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
.button {
  background-color: #888d93;
  color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13);
  min-width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0 15px;
  cursor: pointer;
}
</style>
