export const tags = [
  {
    title: "Речь",
    value: "speech",
  },
  {
    title: "Шум",
    value: "noise",
  },
  {
    title: "Тишина",
    value: "silence",
  },
];

export const defineTag = (tag) => {
  return tags.find((t) => t.value === tag).title;
};
